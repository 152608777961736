import { useRuntimeConfig } from "#imports";
import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: useRuntimeConfig().public.sentry.dsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate:
    useRuntimeConfig().public.appEnv === "PRODUCTION" ? 0.2 : 1.0,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: [
        "J49d6CtpJlutNRA9KxmBx6JlLYhkaCmhsdXmqRzeiyzQziLZw5pfz905Wun3ULav",
      ],
      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],
});
